import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { isLoggedIn } from "../../utilites/fetch";

export const PrivateRoute = ({
  component: Component,
  ...props
}: RouteProps) => {
  if (!Component) return <Redirect to="/login" />;
  return (
    <Route
      {...props}
      render={(props) =>
        isLoggedIn() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
