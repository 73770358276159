import { toast } from "react-toastify";

interface TokenType {
  access: string;
  refresh: string;
}

let isRefreshing = false;
let failedQueue: Array<any> = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const Fetch = async (module: RequestInfo, init: any): Promise<any> => {
  return await fetch(module, init)
    .then(async (result) => {
      if (result.status === 401) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              init.headers["Authorization"] = "Bearer " + token;
              return Fetch(module, init);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }
        return new Promise(function (resolve, reject) {
          refreshToken().then(() => {
            processQueue(null, getToken().access);
            init.headers["Authorization"] = "Bearer " + getToken().access;
            Fetch(module, init).then((res) => resolve(res?.data));
          });
        });
      }
      return result.json();
    })
    .then((data) => {
      if (data?.code !== 0 && data?.code !== 200) {
        toast.error(data?.description);
      } else {
        return {
          data,
          error: null,
        };
      }
    })
    .catch((error) => {
      toast.error(error.message);
      return { data: undefined, error };
    });
};

export const saveToken = (token: TokenType) => {
  localStorage.setItem("tokenData", JSON.stringify(token));
};

export const getToken = () =>
  JSON.parse(localStorage.getItem("tokenData") || "{}");

export const refreshToken = async () => {
  if (isRefreshing) {
    return;
  }
  isRefreshing = true;
  const refresh = getToken().refresh;
  await fetch("/wt/auth/refresh", {
    method: "POST",
    body: JSON.stringify({ refresh }),
  })
    .then((result) => {
      return result.json();
    })
    .then((data) => {
      if (data.code === 400) {
        removeToken();
        return;
      }
      if (data.code !== 0 && data.code !== 200) {
        toast.error(data.description);
      } else {
        saveToken({ access: data?.access, refresh: data?.refresh });
        isRefreshing = false;
      }
    });
};

export const isLoggedIn = () => localStorage.getItem("tokenData");

export const removeToken = async () => {
  await (getToken() && window.localStorage.removeItem("tokenData"));
  window.location.replace("/login");
};
