import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./components/private-router";
import Loader from "./ui/loader";

const Login = lazy(() => import("./pages/login"));
const Cabinet = lazy(() => import("./pages/cabinet"));

const Routes = () => {
  return (
    <Suspense fallback={<Loader position="fixed" />}>
      <main>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            render={() => <Redirect to="/cabinet" />}
          />
          <Route path="/cabinet" component={Cabinet} />
          <Route path="/login" component={Login} />
        </Switch>
      </main>
    </Suspense>
  );
};

export default Routes;
